export default [
  {
    name: "skuNumber",
    label: "Sku",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "height",
    label: "Height",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "width",
    label: "Width",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "depth",
    label: "Depth",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "weight",
    label: "Weight",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "itemCount",
    label: "Count",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "locationAddress",
    label: "Location",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "measurementType",
    label: "Type",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "unitOfMeasurement",
    label: "Unit",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "deviceId",
    label: "Device",
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: "stringMeasureDate",
    label: "Date",
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: "measureDate",   
    options: {
      display: false,
      customBodyRender: ()=>"",
      filter: false,
      sort: false
    }
  }
];
