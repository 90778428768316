const padN = (n, padding = 1)=>{
   //debugger
   let zeroes = new Int32Array(padding);
   if(n<10)
      return `${zeroes.join("")}${n}`;
   else
      return n;
}

export const dateFormat = (d)=>{
   return [padN(d.getMonth()+1),
      padN(d.getDate()),
      padN(d.getFullYear())].join('-')+' '+
              [d.getHours(),
               d.getMinutes(),
               d.getSeconds()].join(':');
}

export const downloadDateFormat = (d)=>{
   return [padN(d.getMonth()+1),
      padN(d.getDate()),
      padN(d.getFullYear())].join('-');
}