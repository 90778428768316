import {
    SET_QUERY_RESULTS,
    APPEND_QUERY_RESULTS,
    DOWNLOAD_FORMAT_COMMA,
    SET_DOWNLOAD_FORMAT
} from 'lib/types'
import FabricQuery from "lib/fabricQuery";

const initialState = {
    downloadFormat: DOWNLOAD_FORMAT_COMMA,
    measureRecords: [],
    query: null
};

export default (state = initialState, action)=> {
    switch(action.type){
        case SET_DOWNLOAD_FORMAT:          
            return {
                ...state,
                downloadFormat: action.payload
            }
        case SET_QUERY_RESULTS:          
            return {
                ...state,
                measureRecords: [...action.payload.measureRecords],
                query: FabricQuery.fromObject(action.payload.query)
            }
        case APPEND_QUERY_RESULTS:          
            return {
                ...state,
                measureRecords: [
                    ...state.measureRecords ,
                    ...action.payload.measureRecords
                ],
                query: FabricQuery.fromObject(action.payload.query)
            }
        default:
            return {...state}
    }
}