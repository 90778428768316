import {
    SET_QUERY_RESULTS, 
    APPEND_QUERY_RESULTS,
    SET_DOWNLOAD_FORMAT,
    DOWNLOAD_FORMAT_COMMA,
    DOWNLOAD_FORMAT_PIPE
} from 'lib/types';
import FabricQuery from "lib/fabricQuery";
import FabricClient from "lib/fabricClient";

const downloadFormats = [
    DOWNLOAD_FORMAT_COMMA,
    DOWNLOAD_FORMAT_PIPE
];

export const queryRecords = (filter = "", takeCount = 100) =>  async dispatch => {
    
    try{
        const query = new FabricQuery();
        query.FilterString = filter;
        query.TakeCount = takeCount;

        /* authenticate user */
        const {nextToken, results} = await FabricClient.measureQuery(query);
        /* set query nextToken */
        query.nextToken = nextToken;
        /* dispatch user */
        dispatch({
            type: SET_QUERY_RESULTS,
            payload: {
                measureRecords: results,
                query
            }
        });
        /* return null meaning it was successful */
        return null;
    }catch(e){  
        return e.message;
    }
}
export const queryNext = () =>  async (dispatch, getState) => {
    
    try{
        /* the query */
        let {query} = getState().dataReducer;;

        /* authenticate user */
        const {nextToken, results} = await FabricClient.measureQuery(query);
        /* set query nextToken */
        query.nextToken = nextToken;
        /* dispatch user */
        dispatch({
            type: APPEND_QUERY_RESULTS,
            payload: {
                measureRecords: results,
                query
            }
        });
        /* return null meaning it was successful */
        return null;
    }catch(e){  
        return e.message;
    }
}

export const switchDownloadFormat = (currentFormat) =>  dispatch => {
    
    let nextFormatIndex = (downloadFormats.indexOf(currentFormat) + 1) % downloadFormats.length;
    let nextFormat = downloadFormats[nextFormatIndex];
    /* dispatch user */
    dispatch({
        type: SET_DOWNLOAD_FORMAT,
        payload: nextFormat
    });

}