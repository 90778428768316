/* external imports */
import React, { Component } from "react";
import { connect } from "react-redux";
/* local imports */
import { queryRecords } from "actions/dataActions";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import tableColumns from "lib/tableColumns";
import {downloadDateFormat} from 'lib/utils';
import CustomToolbar from "components/customTableToolbar";
/* this component initial state */

const tableOptions = {
  selectableRows: 'none',
  selectableRowsHeader: false,
  rowsPerPageOptions: [10,20,30,40,50],
  rowsPerPage: 20,
  print: false,
  customSort: (data, colIndex, order) => {
    return data.sort((a, b) => {
      console.log(colIndex, order);
      return (a.data[colIndex] < b.data[colIndex] ? -1: 1 ) * (order === 'desc' ? 1 : -1);
    });
  }
};

class DataComponent extends Component {
  
  state = {
    displayData: []
  }

  constructor(props){
    super(props);
    const {measureRecords} = this.props;
    this.setState({
      displayData: measureRecords
    })
  }

  componentWillMount(){
    const {queryRecords} = this.props;
    (async ()=>{
      
      /* this is how to implement a filter for the next iteration */
      // let filter = TableQuery.int32Filter("weight",QueryComparisons.GREATER_THAN, 3);
      // let query = new FabricQuery();
      // query.FilterString = filter;
      // query.takeCount = 2;  

        const msg = await queryRecords();
        /* TODO: if msg is not null, show alert */
    })();
  }
    /**
     * Renders the template component.
     * @returns {React.Component}
     */
	render() {

        const {downloadFormat, measureRecords}  = this.props;
        //debugger
        /* set the download format for the table */
        tableOptions.downloadOptions = {
           filename: `sku-cube-measures-${downloadDateFormat(new Date())}.csv`,
           separator: downloadFormat
        };

        tableOptions.onTableChange = (action, tableState) => {
          if(action === "filterChange" || action === "resetFilters" || action === "propsUpdate")
            this.setState({
              displayData: tableState.displayData
            })
        }

        tableOptions.customToolbar = () => {
          return (
            <CustomToolbar  data={this.state.displayData} />
          );
        }

        tableOptions.download = false;

        const tableTheme = () => createMuiTheme({
            overrides: {
              MUIDataTable: {
                paper: {
                  padding: "10px"
                }
              },
              MUIDataTableBodyCell: {
                root: {
                  padding: "0px"
                }
              }
            }
          })

		return (
			<div style={styles.div}>
        <MuiThemeProvider theme={tableTheme()}>
            <MUIDataTable
                title={"Measures"}
                data={measureRecords}
                columns={tableColumns}
                options={{...tableOptions}}
                />
        </MuiThemeProvider>
			</div>
		);
	}
}
/* define the component CSS styles */
const styles = {
	div: {
		fontSize: 48,
		height: '100%',
		textAlign: 'center',
		background: "white"
	}
};
/* PropTypes for data type validation */
DataComponent.propTypes = {
}
/* map the redux state values to component properties */
const mapStateToProps = (state, ownProps) => {
	return {
        measureRecords: state.dataReducer.measureRecords,
        downloadFormat: state.dataReducer.downloadFormat
	};
};
/* map the dispatching actions to component properties */
const mapDispatchToProps = {
  queryRecords
};
/* export the component wrapping it inn the Redux connect mechanism */
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(DataComponent);
